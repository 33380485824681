<template>
	<div class="full-height">

		<div class="full-height">
			<div
				class="pa-10 text-right box"
			>
				<select
					v-model="item_do.year"
					class="pa-5 box mr-10"
				>
					<option
						v-for="year in year_list"
						:key="'year_' + year"
						:value="year"
					>{{ year }}년</option>
				</select>

				<select
					v-model="item_do.month"
					class="pa-5 box mr-10"
				>
					<option
						v-for="month in 12"
						:key="'month_' + month"
						:value="month"
					>{{ month }}월</option>
				</select>

				<select
					v-model="item_do.date"
					class="pa-5 box mr-10"
				>
					<option
						v-for="day in 31"
						:key="'day_' + day"
						:value="day"
					>{{ day }}일</option>
				</select>

				<button
					class="pa-5-10 btn-success"
					@click="postRegular"
				>정기 결제 생성</button>
			</div>


			<div class="mt-10 pa-10 bg-white">
				<table class="table table-even">
					<thead>
					<tr>
						<th>결제일</th>
						<th>총 건수</th>
						<th>생성일시</th>
						<th>변경일시</th>
						<th>관리</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="(item, index) in item_list"
						:key="'regular_' + index"
					>
						<td>{{ item.year }}.{{ item.month }}.{{ item.date }}</td>
						<td>{{ item.total_count }}</td>
						<td>{{ item.wDate }}</td>
						<td>{{ item.mDate }}</td>
						<td><button
							@click="toList(item)"
							class="pa-10 btn-success "
						>상세보기</button></td>
					</tr>
					</tbody>
				</table>

				<Pagination
					:program="program"
					:options="item_search"
					:align="'center'"

					@click="getSearch"
					class="mt-auto"
				></Pagination>
			</div>
		</div>
	</div>
</template>

<script>
import Pagination from "../../components/Pagination";
export default {
	name: 'OrderRegular'
	, components: {Pagination}
	, props: ['Axios', 'TOKEN']
	, data: function(){
		return {
			program: {
				name: '정기 결제'
				,top: true
				,title: true
				,bottom: false
			}
			,items: []
			,year_start: 2021
			,item_do: {
				MTOKEN: this.TOKEN
				,year: new Date().getFullYear()
				,month:  new Date().getMonth() + 1
				,date: new Date().getDate()
			}
			, item_search: {
				page: 1
				, list_cnt: 10
			}
		}
	}
	,computed: {
		item_list: function(){
			return this.items.filter(function(item){
				return item
			})
		}
		,year_list: function(){
			let start = 2021
			let date = new Date()
			let year = date.getFullYear()
			let years = []
			for(let i = start; i <= year; i++){
				years.push(i)
			}

			return years
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getRegularManagement'
					,data: this.item
				})

				if(result.success){
					this.items = result.data.result
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(page){
				this.item_search.page = page
			}

			this.getData()
		}
		,postRegular: async function(){

			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'post'
					,url: 'schedule/postRegular'
					,data: this.item_do
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toList: function(item){
			this.$emit('push', { name: 'RegularItems', params: { year: item.year, month: item.month, date: item.date }})
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>